<template>
    <section v-loading="loading" class="container">
      <div class="content">
        <div class="">
          <div class="row w-100 mb-4">
            <div class="col-12 pl-3 px-0 mb-0 text-left">
              <!-- <BreadCrumbComponent :pageTitles="['Account Settings']" /> -->
            </div>
          </div>
          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Account Settings</strong>
              </div>
            </div>
          </div>
  
          <div class="">
            <div class="TheProfileSettings">
              <div class="theProfile">
                <div style="width: 100px; height: 100px; border-radius: 50%">
                  <img
                    :src="profile_picture"
                    v-if="profile_picture"
                    alt=""
                    class="img-fluid"
                    style="
                      width: 100px;
                      height: 100px;
                      object-fit: cover;
                      border-radius: 100%;
                    "
                  />
                  <div
                    v-if="profile_picture == null"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background-color: red;
                      color: white;
                      width: 100px;
                      height: 100px;
                      object-fit: cover;
                      border-radius: 100%;
                    "
                  >
                    <strong>{{ getInitials(`${$store.state.fullName}`) }}</strong>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="theProfileBtns">
                  <div>
                    <el-button
                      style="
                        width: 150px;
                        background-color: var(--vu-red);
                        color: white;
                      "
                      class="z-depth"
                      v-ripple="'rgba(255, 255, 255, 0.35)'"
                      @click="profilePictureModal = true"
                      >Upload</el-button
                    >
  
                    <button
                      v-if="!removingProfilePicture"
                      type="button"
                      class="btn remove-picture-btn"
                      @click="removeProfilePicture"
                      v-ripple="'rgba(255, 255, 255, 0.35)'"
                    >
                      <span style="position: relative; bottom: 4px">Remove </span>
                    </button>
                    <ScaleOut v-else :background="'#164B70'" />
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-12 px-0 pt-2">
              <hr />
            </div>
  
            <div class="col-12 text-left px-0" style="font-weight: 600">
              Basic Info
            </div>
  
            <div
              class="col-12-px-0 pt-2 text-left"
              style="font-weight: 400; font-size: 0.9em"
            >
              <i
                class="fas fa-info-circle pr-2"
                style="color: rgba(0, 0, 0, 0.3)"
              ></i>
              Some user information such as email, name can not be updated. If you
              wish to update them,
              <strong
                style="
                  font-weight: 600;
                  color: var(--el-app-primary);
                  cursor: pointer;
                "
                @click="userRequestsModal = true"
                >please click here.</strong
              >
            </div>
  
            <!-- BASIC USER INFO -->
  
            <div class="row mt-4 text-left">
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                First Name
                <div class="mt-1">
                  <input
                    type="text"
                    placeholder="First Name"
                    class="legacy-input bg-white"
                    style="width: 100%; height: 36px !important"
                    v-model="$store.state.firstName"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                Last Name
                <div class="mt-1">
                  <input
                    type="text"
                    placeholder="Last Name"
                    class="legacy-input bg-white"
                    style="width: 100%; height: 36px !important"
                    disabled
                    v-model="$store.state.lastName"
                  />
                </div>
              </div>
            </div>
  
            <div class="row mt-2 text-left">
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                Email address
                <div class="mt-1">
                  <input
                    type="email"
                    placeholder="email"
                    class="legacy-input bg-white"
                    style="width: 100%; height: 36px !important"
                    v-model="$store.state.email"
                    disabled
                  />
                </div>
              </div>
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                Phone Number
                <div class="mt-1">
                  <input
                    type="text"
                    @keyup.enter="savePhoneInfo"
                    v-model="phoneNumber"
                    placeholder="Phone Number"
                    class="legacy-input bg-white"
                    style="width: 100%; height: 36px !important"
                  />
                </div>
              </div>
            </div>
  
            <div class="row mt-4 text-right">
              <div class="col-md-8">
                <button
                  v-if="!submiting3"
                  type="button"
                  @click="savePhoneInfo"
                  class="btn z-depth-0 changeBtn"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  style=""
                >
                  <span style="position: relative; bottom: 1px"
                    >Change phone number</span
                  >
                </button>
                <ScaleOut v-else :background="'#164B70'" />
              </div>
            </div>
  
            <div class="col-12 px-0 pt-2">
              <hr />
            </div>
  
            <!-- CHANGE PASSWORD SECTION -->
            <div class="col-12 text-left px-0" style="font-weight: 600">
              Change Password
            </div>
  
            <div class="row mt-2 text-left">
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                Current Password
                <div class="mt-1">
                  <div style="position: relative">
                    <input
                      type="password"
                      id="password"
                      v-model="oldPassword"
                      @keyup.enter="changePassword"
                      :class="{ 'input-error': oldPassword }"
                      placeholder="Current Password"
                      class="legacy-input bg-white"
                      style="width: 100%; height: 36px !important"
                    />
                    <br />
                    <span
                      v-if="oldPassword"
                      style="font-size: 0.7em; color: red"
                      >{{ oldPasswordError }}</span
                    >
                    <div
                      style="
                        position: absolute;
                        right: 5px;
                        top: 0px;
                        bottom: 0px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span @click="switchVisibility()"
                        ><i class="far fa-eye"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row mt-2 text-left">
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                New Password
                <div class="mt-1">
                  <div style="position: relative">
                    <input
                      type="password"
                      id="password1"
                      v-model="newPassword"
                      @keyup.enter="changePassword"
                      placeholder="New Password"
                      class="legacy-input bg-white"
                      style="width: 100%; height: 36px !important"
                    />
                    <br />
                    <span
                      v-if="newPassword"
                      style="font-size: 0.7em; color: red"
                      >{{ newPasswordError }}</span
                    >
                    <div
                      style="
                        position: absolute;
                        right: 5px;
                        top: 0px;
                        bottom: 0px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span @click="switchVisibility1()"
                        ><i class="far fa-eye"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-4"
                style="
                  font-weight: 500;
                  color: rgba(0, 0, 0, 0.7);
                  font-size: 0.9em;
                "
              >
                Confirm Password
                <div class="mt-1">
                  <div style="position: relative">
                    <input
                      type="password"
                      id="password2"
                      v-model="confirmNewPassword"
                      @keyup.enter="changePassword"
                      placeholder="Confirm Password"
                      class="legacy-input bg-white"
                      style="width: 100%; height: 36px !important"
                    />
                    <br />
                    <span
                      v-if="confirmNewPassword"
                      style="font-size: 0.7em; color: red"
                      >{{ confirmNewPasswordError }}</span
                    >
                    <div
                      style="
                        position: absolute;
                        right: 5px;
                        top: 0px;
                        bottom: 0px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                      "
                    >
                      <span @click="switchVisibility2()"
                        ><i class="far fa-eye"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4 text-right">
              <div class="col-md-8">
                <button
                  v-if="!submiting"
                  type="button"
                  class="btn z-depth-0 changeBtn"
                  v-ripple="'rgba(255, 255, 255, 0.35)'"
                  @click="changePassword"
                  style=""
                >
                  <span style="position: relative; bottom: 1px"
                    >Change Password</span
                  >
                </button>
                <ScaleOut v-else :background="'#164B70'" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section>
          <div class="row w-100">
            <div class="col-12 text-left">
              <div
                style="
                  font-size: 20px;
                  font-weight: 400;
                  color: var(--el-app-primary);
                  margin-bottom: 15px;
                "
              >
                <strong>Change Currency</strong>
              </div>
            </div>
          </div>
        <div class="box">
                <el-select
                  v-model="currentCurrency"
                  placeholder="Select"
                  size="mini"
                  @change="chooseCurrency"
                >
                  <el-option
                    v-for="item in supported_currencies"
                    :key="item.key"
                    :label="item.text"
                    :value="item.key"
                    :selected="selected_currency == item.key"
                  >
                  </el-option>
                </el-select>
        </div>
      </section>
  
      <section>
        <div class="settings_dialogs">
          <el-dialog
            title="Change Profile Picture"
            :visible.sync="profilePictureModal"
            :destroy-on-close="true"
            :before-close="handleClose"
          >
            <div v-loading="isUploadingFile">
              <div style="text-align: left">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="file">Upload Profile Picture</label>
                      <el-upload
                        class="upload-demo"
                        drag
                        action="''"
                        :on-change="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileLists"
                        :auto-upload="false"
                        :multiple="false"
                      >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em> click to upload</em>
                        </div>
                        <!-- <div class="el-upload__tip" slot="tip">
                                  jpg/png files with a size less than 500kb
                                </div> -->
                      </el-upload>
                    </div>
                  </div>
                </div>
                <div
                  class="d-md-flex justify-content-md-end form-group text-center"
                  style="padding-top: 10px"
                >
                  <el-button
                    style="
                      width: 150px;
                      background-color: var(--vu-red);
                      color: white;
                    "
                    class="z-depth"
                    v-ripple="'rgba(255, 255, 255, 0.35)'"
                    @click="addProfilePicture()"
                    >Save Changes</el-button
                  >
                </div>
  
                <div class="text-center">
                  <div class="text-center">
                    <small>© 2024 Buddo Secondary School, All rights reserved.</small>
                  </div>
                  <div class="text-center">
                    <!-- <small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
                  </div>
                </div>
              </div>
            </div>
          </el-dialog>
        </div>
      </section>
    </section>
  </template>
  
  <script>
  // import BreadCrumbComponent from "../components/bread-crumb-component";
  import ScaleOut from "@/components/scale-out-component.vue";
  import axios from 'axios'
  
  export default {
    components: { ScaleOut },
  
    data() {
      return {
        profilePictureModal: false,
        userRequestsModal: false,
        basicInfoModal: false,
        selectedFile: null,
        loading: false,
        submiting: false,
        removingProfilePicture: false,
        submiting3: false,
        submiting4: false,
        isUploadingFile: false,
  
        accessCode: "SLAU0X89",
  
        firstName: "",
        lastName: "",
        email: "",
        reason: "",
  
        phoneNumber: "",
        profile_picture: "",
  
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
  
        aws_upload_url: {},
        fileLists: [],

        
      selected_currency: '',
      currentCurrency: '',
      supported_currencies: [
        { key: 'UGX', value: 'UGX', text: '🇺🇬 UGX' },
        { key: 'KES', value: 'KES', text: '🇰🇪 KES' },
        { key: 'USD', value: 'USD', text: '🇺🇸 USD' },
        { key: 'BIF', value: 'BIF', text: '🇧🇮 BIF' },
        { key: 'RWF', value: 'RWF', text: '🇷🇼 RWF' },
        { key: 'SSP', value: 'SSP', text: '🇸🇸 SSP' },
        { key: 'TZS', value: 'TZS', text: '🇹🇿 TZS' },
      ],
      };
    },
  
    computed: {
      getInitials() {
        return (nameString) => {
          //  format(parseISO(dateString), "do MMMM yyyy");
          var names = nameString.split(" "),
            initials = names[0].substring(0, 1).toUpperCase();
          if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
          }
          return initials;
        };
      },
    },

    mounted() {
    //   this.getOneStaff();
      this.getCurrentCurrency()
      this.myCountry()
    },
  
    methods: {
      handleClose(done) {
        done();
      },
  
      handlePreview(file) {
        this.fileLists = [file];
        this.selectedFile = file;
      },
      handleRemove() {
        this.selectedFile = null;
      },
  
      getOneStaff() {
        this.loading = true;
        this.loadingError = false;
        this.$http
          .get(`staff/fetch-one/${this.$store.state.userId}`)
          .then(
            (response) => {
              if (
                response.data.success &&
                response.data.message == "STAFF_RETRIEVED_SUCCESSFULLY"
              ) {
                this.staff = response.data.staff;
                this.firstName = this.staff.first_name;
                this.lastName = this.staff.last_name;
                this.email = this.staff.email;
                this.profile_picture = this.staff.profile_picture;
                this.phoneNumber = this.staff.phone_number;
              } else {
                this.loadingError = true;
                this.showFailedMessage(
                  "Fetch Failed",
                  "An unexpected Error occurred. Please try again"
                );
              }
            },
            () => {
              this.loadingError = true;
              this.showFailedMessage(
                "Fetch Failed",
                "An unexpected Error occurred. Please try again"
              );
            }
          )
          .finally(() => (this.loading = false));
      },
  
      async removeProfilePicture() {
        await this.$confirm(
          "This will permanently delete your profile picture. Do you want to continue?",
          "Confirm photo removal",
          {
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        );
  
        try {
          this.removingProfilePicture = true;
          let request = await this.$http.patch(
            `staff/remove-profile-picture/${this.$store.state.userId}`
          );
          if (
            request.data.success &&
            request.data.message == "PROFILE_DELETED_SUCCESSFULLY"
          ) {
            this.showSuccessMessage("Success", "Picture removed successfully");
            this.getOneStaff();
          } else if (request.data.message == "NO_PROFILE_PICTURE_TO_DELETE") {
            this.showWarningMessage(
              "No Picture",
              "No profile picture to be removed"
            );
          } else {
            this.showFailedMessage("Error", "Unable to remove photo");
          }
        } catch (error) {
          if (error.message == "Network Error") {
            this.loadingError = true;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occured, please try again."
            );
          }
        } finally {
          this.removingProfilePicture = false;
        }
      },
  
      async savePhoneInfo() {
        let phoneData = {
          phone_number: this.phoneNumber,
        };
  
        this.submiting3 = true;
  
        try {
          let request = await this.$http.patch(
            `staff/update-phone-number/${this.$store.state.userId}`,
            phoneData
          );
  
          if (
            request.data.success &&
            request.data.message == "STAFF CONTACT UPDATED"
          ) {
            this.showSuccessMessage("Success", "Phone Number changed successfully");
          } else {
            this.$rollbar.warning(
              "LECTURERS FRONT END: Unexpected API response",
              {
                response: request.data,
                request,
              }
            );
            throw "UNEXPECTED API RESPONSE";
          }
        } catch (error) {
          if (error.message === "Network Error") {
            return this.showFailedMessage(
              "Connection Failed",
              "Unable to Connect. Please check your Internet Connection and try again."
            );
          }
          if (error.response) {
            if (error.response.data.message === "INVALID PHONE NUMBER") {
              return this.showWarningMessage(
                "Invalid Phone number",
                "your phone number should be atleast 9 characters and above"
              );
            }
            return this.showFailedMessage(
              "Unable to change phone number",
              "An unexpected Error occurred, please try again"
            );
          }
        } finally {
          this.submiting3 = false;
        }
      },
  
      async addProfilePicture() {
        this.isUploadingFile = true;
        if (this.selectedFile == null) {
          this.showWarningMessage("Empty", "Profile picture is empty");
        }
  
        try {
          let uploadData = {
            file_name: this.selectedFile.raw.name,
            file_size: this.selectedFile.raw.size,
            mime_type: this.selectedFile.raw.type,
          };
  
          this.isUploadingFile = true;
  
          let request = await this.$http.post(
            `staff/get-profile-picture-upload-url/${this.$store.state.userId}`,
            uploadData
          );
  
          if (
            request.data.success &&
            request.data.message == "S3_UPLOAD_URL_GENERATED"
          ) {
            this.aws_upload_url = request.data.aws_upload_url;
            let file_key = request.data.file_key;
            this.uploadResourceToAWS(
              this.aws_upload_url,
              this.selectedFile.raw,
              file_key
            );
          } else if (request.data.message == "UNSUPPORTED_FILE_TYPE") {
            this.showWarningMessage(
              "Unsupported file",
              "The file you are trying to upload is not supported"
            );
          } else throw "UNEXPECTED_RESPONSE";
        } catch (error) {
          if (error.message == "NetworkError") {
            this.isUploadingFile = false;
            return this.showWarningMessage(
              "Connection failed",
              "A network error occurred please try again"
            );
          }
          this.isUploadingFile = false;
          this.showFailedMessage(
            "Upload Failed",
            "Unable to Upload profile picture now, Please try again"
          );
        } finally {
          // this.isUploadingFile = false
        }
      },
  
      // ___________________________________________________ uploading files
      async uploadResourceToAWS(aws_upload_url, selected_file, file_key) {
        this.isUploadingFile = true;
        try {
          const formData = new FormData();
          Object.entries(aws_upload_url.fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append("file", selected_file);
          this.isUploadingFile = true;
          console.log(selected_file);
          let request = await this.$http.post(`${aws_upload_url.url}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: " ",
            },
            onUploadProgress: (progressEvent) => console.log(progressEvent),
          });
  
          if (request.status == 204) {
            console.log(request.headers.location);
            this.onAWSResourceUploaded(request.headers.location, file_key);
          } else throw "UNEXPECTED_RESPONSE";
        } catch (error) {
          console.log(error);
          if (error.message == "Network Error") {
            this.isUploadingFile = false;
            return this.showFailedMessage(
              "Connection failed",
              "A network error occured, please try again."
            );
          }
          this.isUploadingFile = false;
          this.showFailedMessage(
            "Upload Failed",
            "Unable to Upload Resource Now, please try again"
          );
        } finally {
          // this.isUploadingFile = false;
        }
      },
  
      async onAWSResourceUploaded(profile_photo_path_url, file_key) {
        this.isUploadingFile = true;
  
        let profilePhotoAWSData = {
          path_url: profile_photo_path_url,
          file_key: file_key,
        };
  
        try {
          this.isUploadingFile = true;
          let request = await this.$http.patch(
            `staff/on-profile-picture-aws-uploaded/${this.$store.state.userId}`,
            profilePhotoAWSData
          );
          if (
            request.data.success &&
            request.data.message == "PROFILE_PICTURE_UPDATED"
          ) {
            this.getOneStaff();
            this.showSuccessMessage(
              "Success",
              "Profile picture added successfully"
            );
            this.profilePictureModal = false;
          } else {
            throw "ERROR OCCURED";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occured, please try again."
            );
          }
          this.showFailedMessage(
            "Unable to Upload File",
            "An unexpected Error occured, please try again"
          );
        } finally {
          this.isUploadingFile = false;
        }
      },
  
      async changePassword() {

        let passwordData = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword,
        };
  
        this.submiting = true;
  
        try {
          let request = await this.$http.put(
            `staff/changePassword`,
            passwordData
          );
          if (
            request.data.success &&
            request.data.message == "PASSWORD_CHANGED_SUCCESSFULLY"
          ) {
            this.showSuccessMessage("Success", "Password changed successfully");
            this.profilePictureModal = false;
            this.oldPassword = "";
            this.newPassword = "";
            this.confirmNewPassword = "";
          } else if (request.data.message == "INCORRECT_PASSWORD") {
            this.showWarningMessage(
              "Incorrect password",
              "The current password you entered is incorrect"
            );
          } else {
            throw "ERROR OCCURED";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.showFailedMessage(
            "Unable to change password",
            "An unexpected Error occurred, please try again"
          );
        } finally {
          this.submiting = false;
        }
      },
  
      switchVisibility() {
        const passwordField = document.querySelector("#password");
        if (passwordField.getAttribute("type") === "password")
          passwordField.setAttribute("type", "text");
        else passwordField.setAttribute("type", "password");
      },
  
      switchVisibility1() {
        const passwordField = document.querySelector("#password1");
        if (passwordField.getAttribute("type") === "password")
          passwordField.setAttribute("type", "text");
        else passwordField.setAttribute("type", "password");
      },
  
      switchVisibility2() {
        const passwordField = document.querySelector("#password2");
        if (passwordField.getAttribute("type") === "password")
          passwordField.setAttribute("type", "text");
        else passwordField.setAttribute("type", "password");
      },
  
      async sendRequest() {
  
        let requestData = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          reason: this.reason,
        };
  
        this.submiting4 = true;
        try {
          let request = await this.$http.post(`info-update/submit`, requestData);
          if (
            request.data.success &&
            request.data.message == "REQUEST_SUBMITTED_SUCCESSFULLY"
          ) {
            this.showSuccessMessage("Success", "Request submitted successfully");
            this.userRequestsModal = false;
            this.reason = "";
          } else {
            throw "ERROR OCCURED";
          }
        } catch (error) {
          if (error.message == "Network Error") {
            return this.showFailedMessage(
              "Connection failed",
              "A network error occurred, please try again."
            );
          }
          this.showFailedMessage(
            "Unable to send message",
            "An unexpected Error occurred, please try again"
          );
        } finally {
          this.submiting4 = false;
        }
      },

        

      chooseCurrency() {
        this.$store.commit('SET_CURRENT_CURRENCY', this.currentCurrency)
      },
      getCurrentCurrency() {
        this.currentCurrency = this.$store.getters.getCurrentCurrency
      },

      async myCountry() {
        try {
          const request = await axios.get(
            'https://api.ipdata.co/?api-key=5e051ac0f5efa2e17515cecaf23181e39c695b2500c151c98bd3021b'
          )
          if (request.data) {
            this.country = request.data.country_name
            localStorage.setItem('current_country', this.country)
            this.currency_code = request.data.currency.code
            this.isFetching = false
            this.isError = false
          }
        } catch (error) {
          this.isFetching = true
          this.isError = true
        } finally {
          this.loading = false
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .content {
    margin-top: 45px !important;
  }
  
  .TheProfileSettings {
    display: flex;
    align-items: center;
  }
  
  .TheProfileSettings > div:nth-child(2) {
    margin-left: 30px;
  }
  .theProfileBtns > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  input[type="text"],
  [type="email"],
  [type="file"],
  select,
  textarea {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    background-color: #ffffff;
    font-size: 0.9em;
  }
  
  .remove-picture-btn {
    width: auto;
    padding: 0.85rem 1.8rem;
    text-transform: capitalize;
    box-shadow: -1px 0px 9px 0px rgb(0 0 0 / 11%), 0 2px 10px 0 rgb(0 0 0 / 0%) !important;
    height: 40px;
    border-radius: 4px;
    color: red !important;
    background-color: transparent;
    font-weight: 500;
  }
  
  .changeBtn {
    margin: 0px !important;
    width: 216px;
    text-transform: capitalize;
    height: 43px;
    border-radius: 4px;
    background-color: var(--vu-red);
  }
  
  input.legacy-input:focus:not(.input-error) {
    box-shadow: none !important;
  }
  
  @media (max-width: 768px) {
    .content {
      margin-top: 15px !important;
    }
  }
  
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .theProfile {
      display: flex;
      justify-content: center;
    }
    .changeBtn {
      width: 100%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .TheProfileSettings {
      display: block;
    }
    .theProfile {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
    .changeBtn {
      width: 100%;
    }
  }
  </style>
  
  
  <style>
  .settings_request_form .el-select .el-input__inner {
    cursor: pointer;
    /* height: 36px !important; */
    width: 100% !important;
    padding-left: 10px !important;
    text-transform: capitalize;
  }
  .settings_request_form .el-button--primary {
    color: #fff;
    background-color: #409eff !important;
    border-color: #409eff !important;
  }
  
  el-table th > .cell {
    color: black !important;
    font-weight: 500;
  }
  .el-table .cell {
    font-size: 0.9em;
    line-height: 23px;
    font-weight: 300;
  }
  
  .settings_request_form .el-input input {
    font-size: 0.8rem !important;
    font-weight: 400 !important;
    color: #495057 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
  }
  
  .settings_request_form .el-input input:focus {
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
  }
  
  .settings_dialogs .el-dialog {
    width: 50%;
  }
  /* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) {
    .settings_dialogs .el-dialog {
      width: 50%;
    }
  }
  
  /* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .settings_dialogs .el-dialog {
      width: 70%;
    }
  }
  
  /* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
  @media (min-width: 481px) and (max-width: 767px) {
    .settings_dialogs .el-dialog {
      width: 90%;
    }
  }
  
  /* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
  @media (min-width: 320px) and (max-width: 480px) {
    .settings_dialogs .el-dialog {
      width: 90%;
    }
  }
  </style>
  